<template>
	<v-card>
		<s-toolbar
			label="Agregar Pallet al Pre-Packing"
			save
			@save="save()"
			:save="hiddenBtn"
			dark
			color="#8E8F91"
			close
			@close="$emit('closeModalPrePackingList')"
		>
			<template v-slot:options>
				<v-text-field style="width:20px;"
					v-model="searchText"
					class="mt-4"
					dense
					clearable
					@keydown.enter="textSearch"
				></v-text-field>
				<v-tooltip bottom="">
					<template v-slot:activator="{ on }">
						<v-btn x-small text v-on="on" elevation="0" @click="textSearch()">
							<v-icon style="font-size:16px;">mdi-magnify</v-icon>
						</v-btn>
					</template>
					<span>Buscar</span>
				</v-tooltip>
			</template>
		</s-toolbar>
		<v-container>
			<v-row>
				<v-col lg="12" class="pt-0">
					<v-data-table
						item-key="PltID"
						:headers="headers"
						:items="itemsPrePackingList"
						v-model="selectedPrePackingList"
						show-select
						@item-selected="selectedCheck($event)"
						@toggle-select-all="toggleSelectAll($event)"
						:items-per-page="5"
						dense
						:loading="loading"
					>
					<template v-slot:item.PltCorrelative="{item}">
						<v-chip color="info" x-small>
							{{ item.PltCorrelative }}
						</v-chip>
					</template>

					<template v-slot:item.PppNumberShipment="{item}">
						<v-chip color="error" x-small>
							{{ item.PppNumberShipment }}
						</v-chip>
					</template>

					<template v-slot:item.TypeQualityPalletName="{item}">
						<v-chip
							x-small
							dark
							:color="item.TypeQualityPalletName =='Process' ? 'error' : 'light-blue darken-2'">

							{{  item.TypeQualityPalletName}}

						</v-chip>
					</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
	import _sPackingShipment from "../../../services/FreshProduction/PackingShipmentService";
	import _sPackingListShipment from "../../../services/FreshProduction/PackingListService";
	export default {
		props: {
			item: null
		},
		components: { },
		data() {
			return {
				itemsPrePackingList: [],
                selectedPrePackingList: [],
				hiddenBtn: false,
                filter: {
					PltID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0,
				},
                searchText: "",
				loading: true,
				headers: [
					{ text: "PltID", value: "PltID", width: 100 },
                    // { text: "Pre Packing", value: "PppNumberShipment", width: 100 },
					// { text: "Nro. Pallet", value: "PltCorrelative", width: 100 },
					// { text: "Pedido", value: "OrdID", width: 100 },
					{ text: "Cliente.", value: "UwoCustomerName", width: 240 },
					// { text: "Destino", value: "TypeDestinyName", width: 100 },
					// { text: "Cultivo", value: "TypeCultiveName"},
					// { text: "Variedad", value: "VrtDescription" },
					// { text: "Tipo Cultivo", value: "TypeCropName", width: 140 },
					// {
					// 	text: "Cantidad",
					// 	value: "UwoMaterialListNumberBoxes",
					// 	width: 140
					// },
					// { text: "FPB", value: "PldQuantityBoxeName", width: 140 },
					// { text: "Calibre", value: "TypeCaliberName", width: 140 },
					// { text: "Fecha", value: "PltDate", width: 140 },
					// {text:"calidad palet", value:"TypeQualityPalletName", width: 140 },
                    {text:"Nro. Pallet", value:"PltCorrelative"},
					{text:"Tipo Cultivo", value:"TypeCropName"},
					{text:"Cultivo", value:"TypeCultiveName"},
					{text:"Variedad", value:"VrtDescription"},
					{text:"Calibre", value:"TypeCaliberName"},
					{text:"Cantidad", value:"UwoMaterialListNumberBoxes"},
				]
			};
		},

		created() {
			this.getPalletPrePacking();
		},
		
		methods: {

            save() {

                if(this.selectedPrePackingList.length == 0){
                    this.$fun.alert("Error, no se han seleccionado registros", "warning");
                    return;
                }

				var sumBoxe = 0;
				var exist = false;

                this.selectedPrePackingList.forEach((element, index) => {
					console.log("element",element);
					console.log("this.item", this.item);

					if(this.item.UwoCustomerID !== element.UwoCustomerID){
						this.$fun.alert("Error, No se puede generar Pre-Packing con Clientes diferentes. Por favor verifique", "warning")
						exist = true;
						
					}

					if(this.item.TypeDestinyName !== element.TypeDestinyName){
						this.$fun.alert("Error, No se puede generar Pre-Packing con Destino diferentes. Por favor verifique", "warning")
						exist = true;
					}

                    element.OrdID = this.item.OrdID;
                    element.PppNumberShipment = this.item.PckShipmentNumber;
					element.PppPrePackingNumber = this.item.PckPrePackingNumber;
                    element.UsrCreateID = this.$fun.getUserID();
					element.PppPositionNumber = index;
					element.IsAggrement = 1;
					sumBoxe += element.UwoMaterialListNumberBoxes;

                });

				if(exist == false){

					this.item.PckQuantityBoxe += sumBoxe;
					this.item.itemsDetail = this.selectedPrePackingList;
					this.item.IsAggrementPrePacking = 1;

					this.$fun.alert("¿Seguro de generar Pre-Packing, Recuerde que se procederan a actualizar los PT seleccionados con la nueva OV ? " + this.item.OrdID, "question").then(val => {
							if(val.value){
								_sPackingShipment
									.save(this.item, this.$fun.getUserID())
									.then(resp => {
										if(resp.status == 200){
											this.$fun.alert("Pre-Packing generado correctamente", "success")
											this.$emit("closeModalPrePackingList");
										}
									});
							}
						})
				}
            },

			selectedCheck(item) {
				if(item.value == true){
					this.hiddenBtn = true;
				} else {
					this.hiddenBtn = false;
				}
			},
			toggleSelectAll(items) {
				if(items.value == true){
					this.hiddenBtn = true;
				} else {
					this.hiddenBtn = false;
				}
			},
			getPalletPrePacking()
			{
				this.loading= true;
				_sPackingListShipment.getPrePackingList(this.filter, this.$fun.getUserID()).then(resp => {
					this.loading= false;
					this.itemsPrePackingList = resp.data;
				})
			},
            textSearch(){
                this.filter.Search = this.searchText
                this.getPalletPrePacking();
            },
			
		},
	};
</script>

<style>
</style>