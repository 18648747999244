<template>
	<v-container>
		<div>
			<!-- :single-row="false" -->
			<s-crud
				:filter="filter"
				:config="config"
				search-input
				edit
				@save="save($event)"
				:remove="itemsSelected.PckInitialCharge == null"
				no-full
				height="auto"
				title="Embarque"
				ref="crudPackinglist"
				@rowSelected="rowSelected($event)"
				
			>
				<template v-slot:filter>
					<v-container>						
						<v-row style="margin-left: 5px" justify="center">
							<v-col cols="6" md="2" lg="2">
								<s-select-definition
									label="Destino"
									:def="1181"
									v-model="filter.TypeDestiny"
									:clearable = "$fun.isAdmin()"
								></s-select-definition>
							</v-col>
							<v-col cols="6" md="4" lg="4">
								<s-select-customer clearable label="Cliente" v-model="filter.UwoCustomerID"></s-select-customer>
							</v-col>
							<v-col cols="6" md="2" lg="3">
								<s-date
									label="Fecha Inicio"
									v-model="filter.cDateInitial"
								></s-date>
							</v-col>
							<v-col cols="6" md="2" lg="3">
								<s-date
									label="Fecha Fin"
									v-model="filter.cDateFin"
								></s-date>
							</v-col>
							<!-- <v-col cols="6" md="3" lg="3">
								<v-btn color="info" small class="mt-4" rounded @click="clickGeneratedShipment()">Generar Embarque</v-btn>
							</v-col> -->
							
						</v-row>
						<v-row style="margin-left: 5px" justify="center">
							<v-col cols="6" md="2" lg="4">
								<s-select-definition
									label="Planta Procesadora"
									:def="1230"
									v-model="filter.TypePlantProcessing"
								></s-select-definition>
							</v-col>
							<v-col cols="6" md="2" lg="4">
								<s-select-definition
									label="Doc. PrePacking"
									:def="1492"
									v-model="filter.TypeDocPrePac"
									clearable 
								></s-select-definition>
							</v-col>
							<v-col cols="6" md="2" lg="4">
								<s-select-definition
									label="Doc.  4.1"
									:def="1492"
									v-model="filter.TypeDocSenasa"
									clearable 
								></s-select-definition>
							</v-col>
						</v-row>
					</v-container>
				</template>

				<template v-slot:options v-if="$fun.hasSpecialPermission('ADDPALLET') && HideAddPrePacking">
					<v-tooltip bottom="">
						<template v-slot:activator="{ on }">
							<v-btn small text v-on="on" elevation="0" @click="clickModalListShipment()">
							<v-icon style="font-size:16px;">fas fa-list</v-icon>
							</v-btn>
						</template>
						<span>Agregar Pallet</span>
					</v-tooltip>
				</template>
				
				<!-- <template v-slot:PckInitialShip="{ row }">
					<v-btn
						x-small
						:color="row.PckInitialCharge == null ? 'success' : 'warning'"
						rounded
						@click="clickInitial(row)"
						:disabled="row.PckFinCharge !== null"
					>
						{{row.PckInitialCharge == null ? 'Iniciar' : 'Terminar'}}
					</v-btn>
				</template> -->
				<template slot-scope="props">
					<v-container>
						<v-row justify="center">
							<v-col cols="6" md="3" lg="3" >
							<s-text
								label="Codigo"
								v-model="props.item.OrdID"
								number
							></s-text>
						</v-col>
						<v-col cols="6" md="3" lg="3" >
							<s-text
								label="Cliente"
								readonly
								v-model="props.item.UwoCustomerName"
							></s-text>
						</v-col>
						<v-col cols="6" md="3" lg="3">
							<s-text
								label="Fecha"
								readonly
								v-model="props.item.PckDate"
							></s-text>
						</v-col>
						</v-row>
					</v-container>
				</template>

				<template v-slot:PckView="{ row }">
					<v-btn
						x-small
						color="error"
						fab
						@click="generatedPrePacking(row, 1)"
					>
						<v-icon style="font-size: 16px !important"
							>fas fa-eye</v-icon
						>
					</v-btn>
				</template>
				<template v-slot:PckGenerated="{ row }">
					<v-btn
						x-small
						color="info"
						fab
						@click="generatedPrePacking(row, 2)"
					>
						<v-icon style="font-size: 16px !important"
							>fa fa-parking</v-icon
						>
					</v-btn>
				</template>
				<template v-slot:SecCreateDate="{ row }">
					 {{ $fun.formatTimeShortViews(row.SecCreate) }} 
				</template>
				<template v-slot:TypeDocPrePacStatus="{ row }" >
					<div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
						<v-chip
							x-small
							:color="row.TypeDocPrePacStatus"
							>{{
								row.TypeDocPrePacName
							}}
						</v-chip>
						<v-btn v-if="($fun.isAdmin() && row.TypeDocPrePac === 1 )  || row.TypeDocPrePac != 1"
							x-small
							:color="row.TypeDocPrePac===1 ?'error':'default'"						
							@click="updateDocStatus(row, 1)"
							style="margin-left: auto;"
						>
							<v-icon style="font-size: 16px !important"
								>{{row.TypeDocPrePac===1 ? 'fas fa-minus-circle' : 'mdi-note-outline' }}</v-icon
								
							>
						</v-btn>
					</div>
				</template>
				<template v-slot:TypeDocSenasaStatus="{ row }">
					<div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
					
						<v-chip  
							x-small
							:color="row.TypeDocSenasaStatus"
							>{{
								row.TypeDocSenasaName
							}}</v-chip
						>
						<v-btn v-if="($fun.isAdmin() && row.TypeDocSenasa === 1 )  || row.TypeDocSenasa  != 1"
							x-small
							:color="row.TypeDocSenasa===1 ?'error':'default'"												
							@click="updateDocStatus(row, 2)"
							style="margin-left: auto;"
						>
							<v-icon style="font-size: 16px !important"
								>{{row.TypeDocSenasa===1 ? 'fas fa-minus-circle' : 'mdi-note-outline' }}</v-icon							
							>
						</v-btn>
					</div>
				</template>
			</s-crud>

			<v-dialog persistent v-model="openModalPrePack" v-if="openModalPrePack" width="500">
				<prf-packing-rpt-index :item="itemsSelected" @close="closeAll()"></prf-packing-rpt-index>
			</v-dialog>

			<!-- MODAL PARA EDITAR PRE PACKING-->
			<v-dialog persistent v-model="modalEditPrePacking" v-if="modalEditPrePacking" width="600">
				<packing-pre-packing-edit :item="itemsSelected" @closeModalPrePackingEdit="closeModalPrePackingEdit()"></packing-pre-packing-edit>
			</v-dialog>

			<v-dialog persistent v-model="openModalListShipment" v-if="openModalListShipment" width="700">
				<packing-pre-packing-list :item="itemsSelected" @closeModalPrePackingList="closeModalPrePackingList()"></packing-pre-packing-list>
			</v-dialog>
		</div>

	</v-container>
</template>

<script>
	//Service
	import _sPackingListService from "../../../services/FreshProduction/PackingListService";
	import _sPackingShipment from "../../../services/FreshProduction/PackingShipmentService";
	import sSelectCustomer from "../../../components/Sale/SselectClientSap.vue";
	import PrfPackingRptIndex from "./PrfPackingRptIndex.vue";
	import PackingPrePackingEdit from "./PackingPrePackingEdit.vue";
	import PackingPrePackingList from "./PackingPrePackingList.vue";

	export default {
		components: { sSelectCustomer, PrfPackingRptIndex, PackingPrePackingEdit, PackingPrePackingList },

		data: () => ({
			modalEditPrePacking: false,
			itemsSelected: [],
			itemsPrePacking: [],
			openModalPrePack: false,
			openModalListShipment: false,
			HideAddPrePacking: false,
			filter: {
				cDateInitial: "",
				cDateFin: ""
			},
			config: {
				service: _sPackingListService,
				model: {
					PckID: "ID",
					PckDate: "date", 
					SecCreateDate: "date", 
					OrdID: "",
					PckView: "",
					PckGenerated:"",
					PckInitialShip:"",
					TypeDocPrePacStatus:"",
					TypeDocSenasaStatus:""
				},
				headers: [
					/* { text: "Iniciar", value: "PckInitialShip", width: 10 }, */
					{ text: "#", value: "PckID", width: 10 },
					{ text: "Pre-Packing", value: "PckView", width: 10 },
					{ text: "Editar", value: "PckGenerated", width: 10 },
					{ text: "Pre Packing", value: "PckPrePackingNumber" },//, width: 100
					{ text: "Pedido", value: "OrdID" },//, width: 100
					{ text: "Destino.", value: "TypeDestinyName", },//width: 100 },
					{ text: "Cliente.", value: "UwoCustomerName", },//width: 340 },
					{ text: "Fecha", value: "PckDate" },
					{ text: "Hora", value: "SecCreateDate" },
					{ text: "Doc. PrePacking", value: "TypeDocPrePacStatus"},//, width: 180 
					{ text: "Doc. 4.1", value: "TypeDocSenasaStatus" }//, width: 180
				]
			},
		}),

		watch: {
		},

		methods: {


			closeAll(){
				this.openModalPrePack = false
				this.$refs.crudPackinglist.refresh();
			},

			rowSelected(items){
				this.HideAddPrePacking = false;

				if(items !== undefined){
					if(items.length > 0){
						this.itemsSelected = items[0];
						this.HideAddPrePacking = true;
						console.log('rowselected ', this.itemsSelected);
					}
				}
				
			},

			save(item){
				item.OrdID = parseInt(item.OrdID);
				console.log("Guardar",item);
				this.$fun.alert("¿Seguro de generar Pre-Packing, Recuerde que se procederan a actualizar los PT seleccionados con la nueva OV ? " + item.OrdID, "question").then(val => {
							if(val.value){
								_sPackingListService
									.save(item, this.$fun.getUserID())
									.then(resp => {
										if(resp.status == 200){
											this.$fun.alert("Pre-Packing generado correctamente", "success")
											this.openModal = false;
											this.$refs.crudPackinglist.refresh();
										}
									});
							}
						})
			},

			generatedPrePacking(item, xFlag) {
				console.log('rpyt',item);
				this.itemsSelected = item
				_sPackingListService.getPrePacking(item.PckID, this.$fun.getUserID()).then(resp => {
					console.log("Dataaa111",resp.data);
					if(resp.data.length > 0){
						resp.data.forEach(element => {
							element.PltDate = this.$moment(element.PltDate).format(this.$const.FormatDateDB);
							element.PrintDate = this.$moment(element.PrintDate).format(this.$const.FormatDateDB);
						});
					}
						

					this.itemsSelected.itemsDetail = resp.data
					this.itemsPrePacking = resp.data;
				})

				if(xFlag == 1){
					this.openModalPrePack  = true
				}

				if(xFlag == 2){
					this.modalEditPrePacking = true
				}
				
			},

			updateDocStatus(item, xFlag){
				console.log('rpyt',item);
				this.itemsSelected = item;
				this.$fun.alert("¿Seguro de modificar el estado de Documentado del Embarque ->" + item.OrdID, "question").then(val => {
					if(val.value){
						let itemPack = {
							PckID: this.itemsSelected.PckID,
							TypeDocPrePac:  xFlag === 1 ? (item.TypeDocPrePac === 1 ? 2 : 1 ) : this.itemsSelected.TypeDocPrePac,
							TypeDocSenasa:  xFlag === 2 ? (item.TypeDocSenasa === 1 ? 2 : 1 ) : this.itemsSelected.TypeDocSenasa,
							UsrUpdateID: this.$fun.getUserID()
						}
						_sPackingListService.updateDocStatus(itemPack,this.$fun.getUserID()).then(resp => {
							if(resp.status==200){
								this.$fun.alert("Se registro correctamente","success")
								this.$refs.crudPackinglist.refresh();
								console.log("Doc status correctamente")
							}
							else{
								console.log("Error");
							}
						});
						console.log("Save",val.value);
					}
				}).catch(err => {
					console.error(err);
				});
			},

			closeModalPrePackingEdit(){
				this.modalEditPrePacking = false
				this.$refs.crudPackinglist.refresh();
			},

			clickInitial(item){

				
				var message = "";

				if(item.PckInitialCharge == null){
					message = "¿Seguro de Inicar Embarque ?"
					item.SecStatus = 50
				}else{
					message = "¿Seguro de Terminar Embarque ?"
					item.SecStatus = 51
				}

				console.log("Iniciar", item);

				this.$fun.alert(message, "question").then((val) => {
                    if(val.value){
                        _sPackingShipment
								.save(item, this.$fun.getUserID())
								.then((resp) => {
                                    if(resp.status == 200){
                                        this.$fun.alert(
                                            "Actualizado correctamente",
                                            "success"
									    );
										this.$refs.crudPackinglist.refresh();
                                    }
									
								});
                    }
                })
				
			},
			clickModalListShipment(){
				if(this.itemsSelected.length == 0){
					this.$fun.alert("No se han seleccionado registros", "warning")
					return;
				}
				this.openModalListShipment = true
			},
			closeModalPrePackingList(){
				this.openModalListShipment = false
				this.$refs.crudPackinglist.refresh();
			},
		},

	};
</script>
