<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<h3 class="mt-3 ml-6">Editar Pre Packing</h3>
					<v-spacer> </v-spacer>
					<v-btn
						@click="$emit('closeModalPrePackingEdit')"
						class="mt-3 mr-3"
						small
						fab
						color="error"
						><i style="font-size: 16px" class="fas fa-times"></i
					></v-btn>
				</v-row>

				<v-row>
					<v-col class="pb-0">
						<v-divider></v-divider>
					</v-col>
				</v-row>

				<v-row>
					<v-col lg="12" class="pt-0">
						<v-data-table
							:headers="headers"
							:items="itemsPrePacking"
							:loading="dataLoading"
						>
							<template v-slot:item.actions="{ item }">
								<div v-if="$fun.hasSpecialPermission('EDITPREPACKING')">
									<v-btn
										fab
										x-small
										black
										color="error"
										@click="deletePalletPrePacking(item)"
									>
										<i class="fas fa-trash-alt"></i>
									</v-btn>
								</div>
							</template>
							<template v-slot:item.StatusShipmentName="{ item }">
								<div>
									<v-chip 
										v-if="item.IsAggrement == 1"
										small 
										:color="'warning'" 
										:text-color="item.SecStatus ? 'white' : ''" 
										>
										{{ item.IsAggrement == 1 ? 'Adicionado' : '' }}
									</v-chip>
								</div>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

	</div>
</template>

<script>
	import _sPackingShipment from "../../../services/FreshProduction/PackingShipmentService";
	import _sPackingListShipment from "../../../services/FreshProduction/PackingListService";
	import PrfPackingRptOv from "./PrfPackingRptov.vue";
	import PrfPackingRptSenasa from "./PrfPackingRptSenasa.vue";
	export default {
		props: {
			item: null
		},
		components: { PrfPackingRptOv, PrfPackingRptSenasa },
		data() {
			return {
				dataLoading: false,
				itemsPrePacking: [],
				headers: [
					{text:"Acción", value:"actions"},
					{text:"Correlativo", value:"PltCorrelative"},
					{text:"Tipo Cultivo", value:"TypeCropName"},
					{text:"Cultivo", value:"TypeCultiveName"},
					{text:"Variedad", value:"VrtName"},
					{text:"Calibre", value:"TypeCaliberName"},
					{text:"N° Cajas", value:"QuantityBoxes"},
					{text:"Observación", value:"StatusShipmentName"},
				]
			};
		},

		created() {
			this.getPalletPrePacking();
		},

		

		methods: {

			deletePalletPrePacking(item)
			{
				console.log(item);
				this.$fun.alert("Seguro de quitar pallet de prepacking?", "question")
				.then(r => {
					if(r.value)
					{
						item.UsrUpdateID = this.$fun.getUserID();					
						_sPackingListShipment
						.updateprepackingdetail(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Eliminado correctamente", "success");
								this.getPalletPrePacking();
								return;
							}
						})
					}
				})
				console.log('delete ', item);
			},

			getPalletPrePacking()
			{
				this.dataLoading = true;
				_sPackingListShipment.getPrePacking(this.item.PckID, this.$fun.getUserID()).then(resp => {
					console.log("Data q trae",resp.data);
					this.itemsPrePacking = resp.data;
					this.dataLoading = false;
				})
			}

			
		},
	};
</script>

<style>
</style>